import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import { registerApplication, start } from "single-spa";
import * as Sentry from "@sentry/browser";
import { initSentry, registerErrorHandlers } from "@blocksure/blocksure-core/dist/src/utilities";
import { RESET_EMAIL_NAME, JWT } from "@surelync/common";

function storeToken(search) {
    const params = new URLSearchParams(search);
    const error = params.get("jwtError");
    if (error) {
        localStorage.setItem("jwtError", error);
    } else {
        localStorage.setItem("token", params.get(JWT));
    }
}
const updatePasswordPath = "/surelync/updatepassword";

function storeEmail(search) {
    const params = new URLSearchParams(search);
    const email = params.get("email");
    window.localStorage.setItem(RESET_EMAIL_NAME, email);
}

if (window.location.pathname === updatePasswordPath && window.location.search) {
    const { search } = window.location;
    storeToken(search);
    storeEmail(search);
    window.location.href = updatePasswordPath;
} else if (window.location.pathname === "/surelync/login" && window.location.search) {
    storeToken(window.location.search);
    window.location.href = "/surelync/login";
}

// Activate the layout engine once the common (CSS is loaded)
const routes = constructRoutes(document.querySelector("#single-spa-layout"));
const applications = constructApplications({
    routes,
    // eslint-disable-next-line no-restricted-properties
    loadApp: ({ name }) => System.import(name),
});
// Delay starting the layout engine until the common (CSS is loaded)
const layoutEngine = constructLayoutEngine({
    routes,
    applications,
    active: false,
});

applications.forEach(registerApplication);

// eslint-disable-next-line no-restricted-properties
System.import("@surelync/common").then(() => {
    layoutEngine.activate();
    start();
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === "production") {
    initSentry(Sentry, window.env, env.sentryDsn);
    registerErrorHandlers();
}
