//  Sentry instantiation and populates it with env and url-dependent info
export default function initSentry(Sentry, {env, location, version}, dsn, integrations = []) {
    let release;
    // it is release branch or release tag
    if (version && (version.match('release-[0-9]+.[0-9]+'))) {
        const versionArray = version.split('/[-,]+/');
        release = `${versionArray[versionArray.length - 2]}.${versionArray[versionArray.length - 1]}`;
    } else if (version && version.match('[0-9]+\\.[0-9]+\\.[0-9]+')) {
        const versionArray = version.split('.');
        release = `${versionArray[0]}.${versionArray[1]}`;
    } else release = 'master';
    Sentry.init({
        dsn,
        ignoreErrors: [/Request failed with status code 401/],
        environment: `${env}:${location}`,
        integrations,
        release
    });
}

// Cheeky little endpoint we can use to trigger sentry errors
export function sentryTest() {
    throw new Error('Sentry Test Error!');
}
